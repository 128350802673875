.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $dark
  font-size: 1em
  font-weight: 500
  border-radius: 40px
  transition: all ease-out .25s
  background-color: $white
  outline: none !important
  &.green
    background-color: darken($green,12%)
    color: $white
  &.dark
    color: $white !important
    background-color: $dark
    border: 2px solid $green
    &.top
      padding: 0 24px
