.title
  margin: 0
  padding: 0
  color: $light
  font-size: 2em
  font-weight: 600
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 0.85em
  &-cta
    font-size: 2.25em
    font-weight: 400
    line-height: 1.6
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    font-size: 4em
    font-weight: 700
    +v-sm
      zoom: .75
    +v-sm
      zoom: .5
    &-sub
      font-size: 1.25em
      font-weight: 400
      line-height: 1.6
    &-cta
      font-size: 2em
      font-weight: 700
  &-sub
    font-size: 1.05em
    font-weight: 600
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
