header
  nav
    width: 100% !important
    max-width: 100% !important
    display: flex
    justify-content: space-between
    align-items: center
    #logo
      +v-sm
        display: flex !important
        transform: translate(16px, -16px)
        justify-content: space-between
        width: 63%
      +v-xs
        width: 80%
      .p-4
        +v-sm
          transform: translateY(-5px)
    +v-sm
      display: block
      width: 100%
      .menu
        display: block
        width: 100% !important
        height: 100vh
        background-color: $dark
        &.active
          display: none
        a
          display: flex
          justify-content: center
          align-items: center
          height: 80px
          border-top: 2px solid $green
          &.button
            width: 90% !important
            height: 60px !important
            margin: 0 auto

.burger
  position: absolute !important
  top: 50px
  right: 32px
  display: none
  +v-sm
    position: absolute
    top: 28px
    right: 56px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $white
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $white
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)
  +v-xs
    position: absolute
    top: 28px
    right: 56px
    display: block
