html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $light
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $dark
  &.menu-active
    height: 100vh
    overflow: hidden

a, * a
  color: inherit !important
  text-decoration: none !important



footer
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  +v-sm
    display: block
    padding: 40px 20px !important
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5

img
  +v-sm
    width: auto !important
    max-width: 100% !important
    margin-left: auto !important
    margin-right: auto !important
