#top
  padding: 100px 0 120px
  background: transparent url(../../assets/bg-intro.svg) right center no-repeat
  background-size: 50%
  +v-sm
    padding: 40px 0 40px
    background-size: 60%
  +v-xs
    background: transparent url(../../assets/bg-intro.svg) center center no-repeat
    background-size: cover
#info
  background: transparent url(../../assets/star.svg) right center no-repeat
  background-size: 30%
  +v-sm
    padding-bottom: 40px
#about
  background: transparent url(../../assets/poly.svg) left center no-repeat
  background-size: 30%
  +v-sm
    *
      text-align: center !important
#book
  padding: 0 0 120px
  background: #1e1e1e url(../../assets/blob.svg) right center no-repeat
  background-size: 80%
  +v-sm
    height: auto !important
    padding-bottom: 40px
    *
      text-align: center !important
    .s-100
      height: 10px !important
    img
      margin-top: 40px

#features
  padding: 120px 0 120px
  background: transparent url(../../assets/plus.svg)
  +v-sm
    height: auto !important
    padding: 60px 0
    *
      text-align: center !important
    .tc
      .s-80
        height: 0 !important
      .button
        width: 96%
        margin: 0 auto 1px

#marketing
  background: transparent url(../../assets/star.svg) left center no-repeat
  background-size: 30%
  +v-sm
    height: auto !important
    padding: 40px 0
    *
      text-align: center

#vacancies
  padding: 120px 0
  background: #1e1e1e url(../../assets/blob.svg) left center no-repeat
  background-size: 100%
  +v-sm
    padding-top: 60px
    .s-80
      height: 20px !important
#cta
  background: transparent url(../../assets/telegram.jpg) center center no-repeat
  background-size: cover
  background-attachment: fixed
  +v-sm
    height: auto !important
    min-height: auto !important
    padding: 60px 0
    .s-100
      display: none

#blog
  +v-sm
    padding: 60px 0
    .s-100,
    .s-40
      display: none

#partners
  height: 60vh
  background: rgba(0,0,0,.1)
  +v-sm
    height: auto
    padding: 40px 0
    img
      zoom: .5
.card-vacancy
  background: rgba(255,255,255,.05) url(../../assets/star.svg) right top no-repeat
  background-size: 40%
  +v-sm
    margin-bottom: 24px
    margin-top: 0
.card-blog
  background: rgba(255,255,255,.05) url(../../assets/poly.svg) right bottom no-repeat
  background-size: 80%
  +v-sm
    margin-bottom: 8px
    margin-top: 0
  img
    width: 100%
